import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from "../components/layout"
import SEO from "../components/seo"



const Corso = ({ data }) => {
    const corso = data.corso;
    const immagine = corso.immagineInterna || corso.copertina;
    return (
        <Layout>
            <SEO title={corso.titolo} immagineHero={immagine}/>

            {!!corso.testo &&
            <>
                <div className="section  blog">
                    <div className="row">
                        <div className="col-12">
                            <Img fluid={immagine.fluid}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-8 offset-lg-2">
                            <h1 className="pagetitle">{corso.titolo}</h1>
                            <div className="section__text blog__text" dangerouslySetInnerHTML={{__html: corso.testo.childMarkdownRemark.html}} />
                            <div className="d-flex flex-row justify-content-around">
                                {corso.acquista &&
                                    <div className="shopItem__cta ">
                                        <a target="_blank" rel="noreferrer noopener"  href={corso.acquista} className="btn-action">Acquista il corso</a>
                                    </div>  
                                }
                                <div className="shopItem__cta ">
                                    <a href="mailto:info@giuliaclerici.com" className="btn-action">Scrivimi</a>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>
                {/* {!!corso.testimonianze &&
                    <div className="section">  
                        <div className="row">
                            <div className="col-12 text-center">
                                <h2 className="section__title">Dicono del corso</h2>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-10 offset-1">
                            <Testimonial testimonianze={corso.testimonianze}/>
                            </div>
                        </div>
                    </div>
                } */}
            </>
            }
           
        </Layout>
    )
}

export const query = graphql`
    query CorsoQuery($contentful_id: String!, $locale: String!) {
        corso: contentfulCorsiNew(contentful_id: {eq: $contentful_id}, node_locale: {eq: $locale}){
            titolo
            acquista
            data(formatString: "DD MMMM, YYYY", locale:"it_IT")
            testo {
                childMarkdownRemark {
                    html
                }
            }
            immagineInterna {
                fluid(maxWidth:1120, quality:60) {
                    ...GatsbyContentfulFluid_withWebp
                }
                description
                title
            } 
            copertina {
                title
                description
                fluid(maxWidth:1120, quality:60) {
                  ...GatsbyContentfulFluid_withWebp
                }
            } 
        }  
    }
`;

export default Corso;
